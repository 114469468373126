import {httpConstants} from "../constants";


const createRequestOptions = (method, data, headers) => {
    const { GET } = httpConstants.METHOD_TYPE;
    const requestOptions = {
      method: method,
      headers: headers || { "Content-Type": "application/json" },
    };
  
    if (method !== GET) requestOptions.body = JSON.stringify(data);
    return requestOptions;
  };
export const httpService = (method, url, data, headers) => {
    const requestOptions = createRequestOptions(method, data, headers);
    return fetch(url, requestOptions)
        .then(function handleResponse(response) {
            return response.text().then(text => {
                const data = text && JSON.parse(text);

                if (!data.success) {
                    const error = data.responseCode === 404 ? data : (data && data.message) || response.statusText;
                    return Promise.reject(error);
                }

                return data;
            });
        }).catch(function (err) {
            return err;
        })

};
