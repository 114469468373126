import { httpService } from "../utility/httpService";
import { httpConstants } from "../constants";
const {
  METHOD_TYPE: { POST },
  API_END_POINT: {
    GET_AUTHORIZATION_URL
  },
} = httpConstants;
const headers = { "Content-Type": "application/json" };

export default class UserService {
  baseUrl = process.env.REACT_APP_DATA_SOURCE_MICROSERVICE_URL;
  localUrl = "http://localhost:8000";

  getAuthorizedUrl = async (requestData) => {
    return new Promise((resolve, reject) => {
      httpService(POST, this.baseUrl + GET_AUTHORIZATION_URL, requestData, headers)
        .then((res) => {
          if (res.status !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };
}
